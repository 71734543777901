<script>
import { ref, watch } from "vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import similarPlaylistsModal from "@/common/components/SimilarPlaylistsModal.vue";
import {
  formatNumberIntoHumanizeForm,
  paginate,
  routeToPage,
  sortArray,
  tableCellClassName,
  tableRowClassName,
} from "@/utils/staticHelper";
import moment from "moment";
import { TypeEnums } from "@/common/enums/typeEnums";
export default {
  name: "PlaylistPositionsTable",
  computed: {
    TypeEnums() {
      return TypeEnums;
    },
  },
  methods: {
    formatNumberIntoHumanizeForm,
    routeToPage,
    tableCellClassName,
    tableRowClassName,
  },
  components: { CardToolbar, similarPlaylistsModal },
  props: {
    playlistData: {
      type: Array,
      required: true,
    },
    playlists: {
      type: Array,
      default: () => [],
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    playlistLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    marginClass: {
      type: String,
      default: "mb-5 mb-xl-10",
    },
    showSimilarPlaylistButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const loading = ref(false);
    const tableData = ref([]);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const tableDataWithOutPagination = ref([]);
    const curatorPlaylistID = ref(null);
    const localPagination = ref({
      offset: 1,
      limit: 10,
      total: 0,
      sortBy: "followers",
      sortOrder: "desc",
    });

    const sortResults = ({ prop, order }) => {
      sortBy.value = prop;
      sortDir.value = order;
      if (sortBy.value && sortDir.value) {
        tableDataWithOutPagination.value = sortArray(
          sortBy.value,
          sortDir.value,
          JSON.parse(JSON.stringify(tableDataWithOutPagination.value))
        );
      }
      paginationChanged();
    };

    const paginationChanged = () => {
      tableData.value = paginate(
        tableDataWithOutPagination.value,
        localPagination.value.limit,
        localPagination.value.offset
      );
    };

    watch(
      () => props.playlistData,
      (value) => {
        if (value.length > 0) {
          tableDataWithOutPagination.value = value;
          localPagination.value.total = tableDataWithOutPagination.value.length;
          paginationChanged();
        }
      },
      { immediate: true, deep: true }
    );

    watch(
      () => props.playlistLoading,
      (value) => {
        loading.value = value;
      },
      { immediate: true, deep: true }
    );

    return {
      loading,
      tableData,
      sortBy,
      sortDir,
      tableDataWithOutPagination,
      localPagination,
      curatorPlaylistID,
      paginationChanged,
      sortResults,
      moment,
    };
  },
};
</script>
<template>
  <div class="mt-2 playlist-positions-section">
    <CardToolbar
      :title="title"
      :show-toolbar-buttons="false"
      :fontStyle="true"
      :margin="true"
      :padding="false"
      :shadow-class="true"
      :margin-class="marginClass"
    >
      <template v-slot:body>
        <el-table
          @sort-change="sortResults"
          v-loading="loading"
          :fit="true"
          size="small"
          :data="tableData"
          tooltip-effect="light"
          stripe
          style="width: 100%"
          header-cell-class-name="table-header-text"
          :row-class-name="tableRowClassName"
          :cell-class-name="tableCellClassName"
        >
          <el-table-column
            v-if="tableColumns.includes('trackName')"
            prop="trackName"
            label="Track Name"
            sortable="custom"
            fixed
            width="300"
            class-name="cursor-pointer"
          >
            <template #default="scope">
              <p
                @click="
                  routeToPage(
                    `track-detail/${scope.row.chartmetricTrackID}`,
                    TypeEnums.TRACK,
                    scope.row.chartmetricTrackID
                  )
                "
                class="truncate"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="scope.row.trackName ? scope.row.trackName : ''"
              >
                {{ scope.row.trackName ? scope.row.trackName : "" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            v-if="tableColumns.includes('playlistName')"
            prop="playlistName"
            label="Playlist Name"
            sortable="custom"
            fixed
            width="300"
            class-name="cursor-pointer"
          >
            <template #default="scope">
              <p
                @click="
                  routeToPage(
                    `playlist-detail/${
                      scope.row.curatorPlaylistID
                        ? scope.row.curatorPlaylistID
                        : scope.row.curatorPlaylistID
                    }`,
                    TypeEnums.PLAYLIST,
                    scope.row.curatorPlaylistID
                      ? scope.row.curatorPlaylistID
                      : scope.row.curatorPlaylistID
                  )
                "
                class="truncate"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="
                  scope.row.playlistName ? scope.row.playlistName : ''
                "
              >
                {{ scope.row.playlistName ? scope.row.playlistName : "N/A" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            v-if="tableColumns.includes('ownerName')"
            prop="ownerName"
            label="Curator"
            sortable="custom"
            width="150"
            align="right"
          >
            <template #default="scope">
              <p
                class="truncate"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="scope.row.ownerName ? scope.row.ownerName : ''"
              >
                {{ scope.row.ownerName ? scope.row.ownerName : "N/A" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            v-if="tableColumns.includes('followers')"
            prop="followers"
            label="Playlist Followers"
            sortable="custom"
            width="200"
            align="right"
          >
            <template #default="scope">
              {{
                scope.row.followers
                  ? formatNumberIntoHumanizeForm(scope.row.followers)
                  : 0
              }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="tableColumns.includes('fdiffMonth')"
            prop="fdiffMonth"
            label="Followers Diff Last Month"
            sortable="custom"
            width="200"
            align="right"
          >
            <template #default="scope">
              {{
                scope.row.fdiffMonth
                  ? formatNumberIntoHumanizeForm(scope.row.fdiffMonth)
                  : 0
              }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="tableColumns.includes('position')"
            prop="position"
            label="Position Now"
            sortable="custom"
            width="150"
            align="right"
          >
            <template #default="scope">
              {{ scope.row.position ? scope.row.position : 0 }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="tableColumns.includes('peakPosition')"
            prop="peakPosition"
            label="Peak Position"
            sortable="custom"
            width="150"
            align="right"
          >
            <template #default="scope">
              {{ scope.row.peakPosition ? scope.row.peakPosition : 0 }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="tableColumns.includes('addedAt')"
            prop="addedAt"
            label="Added At"
            sortable="custom"
            width="150"
            align="right"
          >
            <template #default="scope">
              {{
                scope.row.addedAt
                  ? moment(scope.row.addedAt).format("MMM DD, YYYY")
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="tableColumns.includes('period')"
            prop="period"
            label="Days on Playlist"
            sortable="custom"
            :width="showSimilarPlaylistButton ? 150 : 'auto'"
            align="right"
          >
            <template #default="scope">
              {{ scope.row.period ? scope.row.period : 0 }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="tableColumns.includes('status')"
            prop="status"
            label="Status"
            sortable="custom"
            width="150"
            align="right"
          >
            <template #default="scope">
              {{
                scope.row.status
                  ? scope.row.status.charAt(0).toUpperCase() +
                    scope.row.status.slice(1)
                  : "N/A"
              }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="showSimilarPlaylistButton"
            label="Similar Playlists"
            width="120"
            align="right"
          >
            <template #default="scope">
              <a
                v-if="scope.row.curatorPlaylistID"
                @click="curatorPlaylistID = scope.row.curatorPlaylistID"
                class="btn btn-sm btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#show_similar_playlist_modal"
              >
                View
              </a>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt-7 mb-7"
          v-model:currentPage="localPagination.offset"
          v-model:page-size="localPagination.limit"
          :page-sizes="[5, 10, 20, 50, 100]"
          :page-size="localPagination.limit"
          :small="true"
          layout="total, sizes, prev, pager, next"
          :total="localPagination.total"
          @size-change="paginationChanged"
          @current-change="paginationChanged"
        >
        </el-pagination>
      </template>
    </CardToolbar>
    <similarPlaylistsModal
      :curatorPlaylistID="curatorPlaylistID"
      :playlists="playlists"
    />
  </div>
</template>
