<script>
import { onMounted, ref } from "vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import {
  formatNumberIntoHumanizeForm,
  paginate,
  routeToPage,
  sortArray,
  tableCellClassName,
  tableRowClassName,
} from "@/utils/staticHelper";
import moment from "moment";
import { getArtistTracksByChartmetricID } from "@/api/artists.api";
import { ElNotification } from "element-plus";
import { TypeEnums } from "@/common/enums/typeEnums";
export default {
  name: "ArtistTracks",
  computed: {
    TypeEnums() {
      return TypeEnums;
    },
  },
  methods: {
    routeToPage,
    formatNumberIntoHumanizeForm,
    tableCellClassName,
    tableRowClassName,
  },
  props: {
    chartmetricID: {
      type: String,
      required: true,
    },
  },
  components: {
    CardToolbar,
  },
  setup(props) {
    const loading = ref(false);
    const tableData = ref([]);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const tableDataWithOutPagination = ref([]);
    const localPagination = ref({
      offset: 1,
      limit: 10,
      total: 0,
      sortBy: "latestSpotifyPlays",
      sortOrder: "desc",
    });
    onMounted(() => {
      getArtistTracks();
    });
    const getArtistTracks = async () => {
      try {
        loading.value = true;
        const { data } = await getArtistTracksByChartmetricID(
          props.chartmetricID
        );
        tableDataWithOutPagination.value = data && data.data ? data.data : [];
        localPagination.value.total = tableDataWithOutPagination.value.length;

        paginationChanged();
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching artist tracks",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };
    const sortResults = ({ prop, order }) => {
      loading.value = true;
      sortBy.value = prop;
      sortDir.value = order;
      if (sortBy.value && sortDir.value) {
        tableDataWithOutPagination.value = sortArray(
          sortBy.value,
          sortDir.value,
          JSON.parse(JSON.stringify(tableDataWithOutPagination.value))
        );
      }
      paginationChanged();
      loading.value = false;
    };

    const paginationChanged = () => {
      tableData.value = paginate(
        tableDataWithOutPagination.value,
        localPagination.value.limit,
        localPagination.value.offset
      );
    };
    return {
      loading,
      tableData,
      sortDir,
      sortBy,
      localPagination,
      sortResults,
      paginationChanged,
      moment,
    };
  },
};
</script>
<template>
  <div class="mt-2 artist-tracks-section">
    <CardToolbar
      v-loading="loading"
      title=""
      :show-toolbar-buttons="false"
      :fontStyle="true"
      :margin="true"
      :padding="true"
      :shadow-class="true"
      :show-header="false"
    >
      <template v-slot:body>
        <el-table
          @sort-change="sortResults"
          :fit="true"
          size="small"
          :data="tableData"
          tooltip-effect="light"
          stripe
          style="width: 100%"
          header-cell-class-name="table-header-text"
          :row-class-name="tableRowClassName"
          :cell-class-name="tableCellClassName"
        >
          <el-table-column
            prop="trackName"
            label="Track Name"
            sortable="custom"
            class-name="cursor-pointer"
          >
            <template #default="scope">
              <p
                @click="
                  routeToPage(
                    `track-detail/${scope.row.chartmetricTrackID}`,
                    TypeEnums.TRACK,
                    scope.row.chartmetricTrackID
                  )
                "
                class="truncate"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="scope.row.trackName"
              >
                {{ scope.row.trackName }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="latestSpotifyPlays"
            label="Total Spotify Streams"
            sortable="custom"
          >
            <template #default="scope">
              {{
                scope.row.latestSpotifyPlays
                  ? formatNumberIntoHumanizeForm(scope.row.latestSpotifyPlays)
                  : 0
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="monthlySpotifyPlays"
            label="Spotify Streams Last Month"
            sortable="custom"
          >
            <template #default="scope">
              {{
                scope.row.monthlySpotifyPlays
                  ? formatNumberIntoHumanizeForm(scope.row.monthlySpotifyPlays)
                  : 0
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="timestp"
            label="Release Date"
            sortable="custom"
          >
            <template #default="scope">
              {{
                scope.row.timestp
                  ? moment(scope.row.timestp).format("MMM DD, YYYY")
                  : ""
              }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt-7 mb-7"
          v-model:currentPage="localPagination.offset"
          v-model:page-size="localPagination.limit"
          :page-sizes="[5, 10, 20, 50, 100]"
          :page-size="localPagination.limit"
          :small="true"
          layout="total, sizes, prev, pager, next"
          :total="localPagination.total"
          @size-change="paginationChanged"
          @current-change="paginationChanged"
        >
        </el-pagination>
      </template>
    </CardToolbar>
  </div>
</template>
