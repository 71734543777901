<script>
import { onMounted, ref } from "vue";
import SubTopNavBar from "@/common/components/SubTopNavBar.vue";
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import ArtistTracks from "@/artist/components/ArtistTracks.vue";
import {
  getArtistDetailByChartmetricID,
  getArtistListenersByChartmetricID,
  getArtistPlaylistsByChartmetricID,
  saveArtistDetailByChartmetricID,
} from "@/api/artists.api";
import { useRoute } from "vue-router";
import { checkIsValueAvailableOrNot, getTagName } from "@/utils/staticHelper";
import CardToolbar from "@/common/components/CardToolbar.vue";
import LineAndAreaChart from "@/common/components/LineAndAreaChart.vue";
import PlaylistPositionsTable from "@/common/components/PlaylistPositionsTable.vue";
import { ElNotification } from "element-plus";
import moment from "moment/moment";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import ArtistWishlists from "@/artist/components/ArtistWishlists.vue";
export default {
  name: "ArtistDetail",
  components: {
    LineAndAreaChart,
    CardToolbar,
    SubTopNavBar,
    KpiCardV2,
    ArtistTracks,
    PlaylistPositionsTable,
    EmptyCardLoading,
    ArtistWishlists,
  },
  setup() {
    const loading = ref(false);
    const updateLoading = ref(false);
    const listenersLoading = ref(true);
    const playlistLoading = ref(false);
    const listenersCategories = ref([]);
    const listenersSeries = ref([]);
    const annotations = ref({ xaxis: [] });
    const route = useRoute();
    const chartmetricID = route.params.chartmetricArtistID;
    const artistDetail = ref({});
    const listenersAnnotationsLegends = ref([]);
    const currentPlaylists = ref([]);
    const pastPlaylists = ref([]);
    const artistName = ref("");
    const tabMenuList = ref([
      {
        id: "artist_statistics",
        name: "Statistics",
        icon: "/media/svg/playlist.svg",
        alt: "statistics",
      },
      {
        id: "artist_listeners",
        name: "Listeners",
        icon: "/media/svg/playlist.svg",
        alt: "listeners",
      },
      {
        id: "artist_track_table",
        name: "Tracks",
        icon: "/media/svg/playlist.svg",
        alt: "playlist_tracks",
      },
      {
        id: "artist_playlist",
        name: "Playlists",
        icon: "/media/svg/playlist.svg",
        alt: "playlists",
      },
      {
        id: "artist_wishlist",
        name: "Wishlists",
        icon: "/media/svg/playlist.svg",
        alt: "wishlists",
      },
    ]);
    const tableColumns = ref([
      "trackName",
      "playlistName",
      "ownerName",
      "followers",
      "fDiffMonth",
      "position",
      "peakPosition",
      "addedAt",
      "period",
    ]);
    onMounted(() => {
      document.title = "Artist";
      getArtistDetail();
      getListenersData();
      getArtistPlaylists();
    });
    const getArtistDetail = async () => {
      try {
        loading.value = true;
        const { data } = await getArtistDetailByChartmetricID(chartmetricID);
        const meta =
          data && data.data && data.data.chartmetricMeta
            ? data.data.chartmetricMeta
            : {};
        artistName.value = meta.name && meta.name ? meta.name : "";
        document.title =
          meta.name && meta.name ? "Artist | " + meta.name : "Artist";

        const cm_stats = meta && meta.cm_statistics ? meta.cm_statistics : {};

        artistDetail.value["spotifyListeners"] = checkIsValueAvailableOrNot(
          "object",
          cm_stats,
          "sp_monthly_listeners"
        );
        artistDetail.value["spotifyFollowers"] = checkIsValueAvailableOrNot(
          "object",
          cm_stats,
          "sp_followers"
        );
        artistDetail.value["spotifyPopularity"] = checkIsValueAvailableOrNot(
          "object",
          cm_stats,
          "sp_popularity"
        );
        artistDetail.value["spotifyPlaylists"] = checkIsValueAvailableOrNot(
          "object",
          cm_stats,
          "num_sp_playlists"
        );
        artistDetail.value["spotifyEditorialPlaylists"] =
          checkIsValueAvailableOrNot(
            "object",
            cm_stats,
            "num_sp_editorial_playlists"
          );
        artistDetail.value["spotifyPlaylistReach"] = checkIsValueAvailableOrNot(
          "object",
          cm_stats,
          "sp_playlist_total_reach"
        );
        artistDetail.value["spotifyPlaylistEditorialReach"] =
          checkIsValueAvailableOrNot(
            "object",
            cm_stats,
            "sp_editorial_playlist_total_reach"
          );
        artistDetail.value["deezerPlaylists"] = checkIsValueAvailableOrNot(
          "object",
          cm_stats,
          "num_de_playlists"
        );
        artistDetail.value["deezerEditorialPlaylists"] =
          checkIsValueAvailableOrNot(
            "object",
            cm_stats,
            "num_sp_editorial_playlists"
          );
        artistDetail.value["deezerPlaylistReach"] = checkIsValueAvailableOrNot(
          "object",
          cm_stats,
          "de_playlist_total_reach"
        );
        artistDetail.value["deezerPlaylistEditorialReach"] =
          checkIsValueAvailableOrNot(
            "object",
            cm_stats,
            "de_editorial_playlist_total_reach"
          );
        artistDetail.value["genres"] = getTagName(
          meta && meta.tags ? meta.tags : [],
          "name"
        );
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching artist details",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };
    const getListenersData = async () => {
      try {
        listenersLoading.value = true;
        annotations.value.xaxis = [];
        const { data } = await getArtistListenersByChartmetricID(chartmetricID);
        const listeners = [];
        const xAxisAnnotations = [];
        if (data.data) {
          if (data.data.listeners) {
            for (const listener of data.data.listeners) {
              listenersCategories.value.push(listener.timestp);
              listeners.push(listener.value);
            }
            listenersSeries.value.push({
              name: "listeners",
              data: listeners,
            });
          }
          if (data.data.currentPlaylists) {
            listenersAnnotationsLegends.value.push({
              text: "Playlist Entry",
              color: "#00cc00",
            });
            for (const value of data.data.currentPlaylists) {
              xAxisAnnotations.push({
                x: moment(value.addedAt).format("MMM YY"),
                strokeDashArray: 10,
                borderColor: "#00cc00",
                borderWidth: 2,
                label: {
                  borderColor: "#00cc00",
                  style: {
                    color: "#fff",
                    background: "#00cc00",
                  },
                  // text: `Curr.Playlist: ${
                  //   value.playlistName
                  // } , Followers: ${formatNumberIntoHumanizeForm(
                  //   value.followers
                  // )}`,
                },
              });
            }
          }
          if (data.data.pastPlaylists) {
            listenersAnnotationsLegends.value.push({
              text: "Playlist Drop",
              color: "#ff0000",
            });
            for (const value of data.data.pastPlaylists) {
              xAxisAnnotations.push({
                x: moment(value.removedAt).format("MMM YY"),
                strokeDashArray: 10,
                borderColor: "#ff0000",
                borderWidth: 2,
                label: {
                  borderColor: "#ff0000",
                  style: {
                    color: "#fff",
                    background: "#ff0000",
                  },
                  // text: `Past.Playlist: ${
                  //   value.playlistName
                  // } , Followers: ${formatNumberIntoHumanizeForm(
                  //   value.followers
                  // )}`,
                },
              });
            }
          }
          // if (data.data.streamsLastMonth) {
          //   listenersAnnotationsLegends.value.push({
          //     text: "Track Streams Last Month",
          //     color: "#FB663B",
          //   });
          //   for (const value of data.data.streamsLastMonth) {
          //     xAxisAnnotations.push({
          //       x: moment(value.timestp).format("MMM YY"),
          //       strokeDashArray: 10,
          //       borderColor: "#FB663B",
          //       borderWidth: 2,
          //       label: {
          //         borderColor: "#FB663B",
          //         style: {
          //           color: "#fff",
          //           background: "#FB663B",
          //         },
          //         // text: `Track: ${
          //         //   value.trackName
          //         // } ,Streams L.month: ${formatNumberIntoHumanizeForm(
          //         //   value.monthlySpotifyPlays
          //         // )}`,
          //       },
          //     });
          //   }
          // }
          if (data.data.totalStreams) {
            listenersAnnotationsLegends.value.push({
              text: "Releases",
              color: "#ffa500",
            });
            for (const value of data.data.totalStreams) {
              xAxisAnnotations.push({
                x: moment(value.timestp).format("MMM YY"),
                strokeDashArray: 10,
                borderColor: "#ffa500",
                borderWidth: 2,
                label: {
                  borderColor: "#ffa500",
                  style: {
                    color: "#fff",
                    background: "#ffa500",
                  },
                  // text: `Track: ${
                  //   value.trackName
                  // } , Total Streams: ${formatNumberIntoHumanizeForm(
                  //   value.monthlySpotifyPlays
                  // )}`,
                },
              });
            }
          }
          annotations.value.xaxis = xAxisAnnotations;
        }
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching artist listeners",
          type: "error",
        });
      } finally {
        listenersLoading.value = false;
      }
    };
    const getArtistPlaylists = async () => {
      try {
        playlistLoading.value = true;
        const { data } = await getArtistPlaylistsByChartmetricID(chartmetricID);
        if (data && data.data) {
          currentPlaylists.value =
            data.data.currentPlaylistPositions &&
            data.data.currentPlaylistPositions.length
              ? data.data.currentPlaylistPositions
              : [];
          pastPlaylists.value =
            data.data.pastPlaylistPositions &&
            data.data.pastPlaylistPositions.length
              ? data.data.pastPlaylistPositions
              : [];
        }
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching artist playlists",
          type: "error",
        });
      } finally {
        playlistLoading.value = false;
      }
    };
    const updateDetail = async () => {
      try {
        updateLoading.value = true;
        await saveArtistDetailByChartmetricID(chartmetricID, true);
        ElNotification({
          title: "Success",
          message: "Request sent for updating artist details",
          type: "success",
        });
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in updating artist details",
          type: "error",
        });
      } finally {
        updateLoading.value = false;
      }
    };
    return {
      loading,
      artistName,
      updateLoading,
      playlistLoading,
      currentPlaylists,
      pastPlaylists,
      listenersLoading,
      listenersCategories,
      listenersSeries,
      annotations,
      tabMenuList,
      artistDetail,
      chartmetricID,
      tableColumns,
      listenersAnnotationsLegends,
      updateDetail,
    };
  },
};
</script>

<template>
  <div
    id="artist_detail"
    class="d-flex justify-content-start"
    v-loading.fullscreen.lock="loading"
  >
    <div class="artist-main-content w-100">
      <div class="d-flex page-heading">
        Artist :
        {{ artistName }}
      </div>
      <sub-top-nav-bar
        :tabs-list="tabMenuList"
        current-active-tab="#artist_statistics"
        main-id="artist_menu_list"
        :sub-list="[]"
        :scroll-to-element-margin-main="80"
        sub-id="artist_sub_id"
        :have-sub-list="false"
        :update-button="true"
        @updateDetail="updateDetail"
        :loading="updateLoading"
      />
      <div class="row mt-8">
        <div id="artist_statistics">
          <div class="artist-content-heading">Statistics</div>
          <div class="mt-2 mb-3 mb-xl-5">
            <el-row :gutter="12">
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="artistDetail.spotifyListeners"
                  :text="'Spotify Listeners'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="artistDetail.spotifyFollowers"
                  :text="'Spotify Followers'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="artistDetail.spotifyPopularity"
                  :text="'Spotify Popularity'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2 :title="artistDetail.genres" :text="'Genres'" />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="artistDetail.spotifyPlaylists"
                  :text="'Spotify Playlists'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="artistDetail.spotifyEditorialPlaylists"
                  :text="'Spotify Editorial Playlists'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="artistDetail.spotifyPlaylistReach"
                  :text="'Spotify Playlist Reach'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="artistDetail.spotifyPlaylistEditorialReach"
                  :text="'Spotify Editorial Playlist Reach'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="artistDetail.deezerPlaylists"
                  :text="'Deezer Playlists'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="artistDetail.deezerEditorialPlaylists"
                  :text="'Deezer Editorial Playlists'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="artistDetail.deezerPlaylistReach"
                  :text="'Deezer Playlist Reach'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="artistDetail.deezerPlaylistEditorialReach"
                  :text="'Deezer Editorial Playlist Reach'"
                />
              </el-col>
            </el-row>
          </div>
        </div>
        <div id="artist_listeners">
          <div class="artist-content-heading">Listeners</div>
          <div class="mt-2 artist-listeners-section">
            <EmptyCardLoading
              :loading="listenersLoading"
              :type="'skeleton'"
              v-if="listenersLoading"
              :card-height="'400px'"
              :rows="9"
              class="mb-5 mb-xl-10"
            />
            <CardToolbar
              v-if="!listenersLoading"
              title=""
              :show-toolbar-buttons="false"
              :fontStyle="true"
              :margin="true"
              :padding="true"
              :shadow-class="true"
              :show-header="false"
            >
              <template v-slot:body>
                <LineAndAreaChart
                  v-if="
                    listenersCategories.length > 0 &&
                    listenersSeries.length > 0 &&
                    annotations.xaxis.length > 0
                  "
                  :categories="listenersCategories"
                  :chart-series="listenersSeries"
                  :annotations="annotations"
                  :chart-type="'line'"
                  :chart-stroke="{
                    curve: 'straight',
                    show: true,
                    width: [3, 1],
                    dashArray: [0, 8],
                  }"
                  :chart-height="450"
                  :zoom="true"
                  :tooltip-x-format="'MMM DD, YYYY'"
                />
                <div
                  class="
                    d-flex
                    listeners-annotation-legends
                    justify-content-center
                  "
                  style="margin: 2px 5px"
                >
                  <div
                    class="position-relative"
                    v-for="(legend, index) in listenersAnnotationsLegends"
                    :key="index"
                  >
                    <span
                      class="
                        listeners-annotation-legend-marker
                        position-absolute
                      "
                      :style="'background:' + legend.color"
                    >
                    </span>
                    <span class="listeners-annotation-legend-text ms-5 me-5">{{
                      legend.text
                    }}</span>
                  </div>
                </div>
              </template>
            </CardToolbar>
          </div>
        </div>
        <div id="artist_track_table">
          <div class="artist-content-heading">Tracks</div>
          <artist-tracks :chartmetricID="chartmetricID" />
        </div>
        <div id="artist_playlist">
          <div class="artist-content-heading">Playlists</div>
          <PlaylistPositionsTable
            :title="'Current Playlist'"
            :playlist-loading="playlistLoading"
            :playlistData="currentPlaylists"
            :tableColumns="tableColumns"
            :margin-class="'margin-bottom-12'"
          />
          <PlaylistPositionsTable
            :title="'Past Playlists'"
            :playlist-loading="playlistLoading"
            :playlistData="pastPlaylists"
            :tableColumns="tableColumns"
          />
        </div>
        <div id="artist_wishlist">
          <div class="artist-content-heading">Wishlists</div>
          <ArtistWishlists />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.listeners-annotation-legend-marker {
  color: rgb(193, 75, 132);
  height: 12px;
  width: 12px;
  left: 0;
  top: 3px;
  border-width: 0;
  border-color: rgb(255, 255, 255);
  border-radius: 12px;
}
.listeners-annotation-legend-text {
  color: rgb(161, 165, 183);
  font-size: 12px;
  font-weight: 400;
  font-family: inherit;
}
</style>
