<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { deleteWishlist, getWishlists } from "@/api/wishlists.api";
import CardToolbar from "@/common/components/CardToolbar.vue";
import {
  routeToPage,
  sortArray,
  tableCellClassName,
  tableRowClassName,
} from "@/utils/staticHelper";
import { DeleteFilled } from "@element-plus/icons-vue";
import { ElNotification } from "element-plus";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "ArtistWishlists",
  methods: { routeToPage, tableCellClassName, tableRowClassName },
  components: { DeleteFilled, CardToolbar },
  computed: {
    moment() {
      return moment;
    },
  },
  setup() {
    const loading = ref(false);
    const wishlists = ref([]);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const route = useRoute();
    onMounted(async () => {
      try {
        loading.value = true;
        const { data } = await getWishlists(route.params.chartmetricArtistID);
        wishlists.value = data && data.length ? data : [];
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    });
    const sortResults = ({ prop, order }) => {
      sortBy.value = prop;
      sortDir.value = order;
      if (sortBy.value && sortDir.value) {
        wishlists.value = sortArray(
          sortBy.value,
          sortDir.value,
          JSON.parse(JSON.stringify(wishlists.value))
        );
      }
    };

    const delWishlist = async (wishlistID) => {
      Swal.fire({
        title: "Delete this Playlist?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            loading.value = true;
            await deleteWishlist(wishlistID);
            wishlists.value = wishlists.value.filter((wishlist) => {
              return wishlist["_id"] !== wishlistID;
            });
            ElNotification({
              title: "Success",
              message: "Wishlist removed successfully",
              type: "success",
            });
          }
        })
        .catch((e) => {
          console.error(e);
          Swal.fire("Oops!", "Error in deleting playlist.", "error");
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return { loading, wishlists, sortBy, sortDir, sortResults, delWishlist };
  },
};
</script>
<template>
  <CardToolbar
    v-loading="loading"
    title=""
    :show-toolbar-buttons="false"
    :fontStyle="true"
    :margin="true"
    :padding="true"
    :shadow-class="true"
    :show-header="false"
  >
    <template v-slot:body>
      <el-table
        @sort-change="sortResults"
        :fit="true"
        size="small"
        :data="wishlists"
        tooltip-effect="light"
        stripe
        style="width: 100%"
        header-cell-class-name="table-header-text"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
      >
        <el-table-column label="Name" prop="name" sortable="custom">
          <template #default="scope">
            <p
              class="truncate cursor-pointer"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="scope.row.name ? scope.row.name : ''"
              @click="routeToPage(`wishlist-detail/${scope.row._id}`)"
            >
              {{ scope.row.name ? scope.row.name : "N/A" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          label="Followers"
          prop="totalWishlistPlaylistsFollowers"
          sortable="custom"
        >
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="
                scope.row.totalWishlistPlaylistsFollowers
                  ? scope.row.totalWishlistPlaylistsFollowers
                  : ''
              "
            >
              {{
                scope.row.totalWishlistPlaylistsFollowers
                  ? scope.row.totalWishlistPlaylistsFollowers
                  : "N/A"
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          label="Total Playlists"
          prop="totalWishlistPlaylists"
          sortable="custom"
        >
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="
                scope.row.totalWishlistPlaylists
                  ? scope.row.totalWishlistPlaylists
                  : ''
              "
            >
              {{
                scope.row.totalWishlistPlaylists
                  ? scope.row.totalWishlistPlaylists
                  : "N/A"
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Created At" prop="createdAt" sortable="custom">
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="scope.row.createdAt ? scope.row.createdAt : ''"
            >
              {{
                scope.row.createdAt
                  ? moment(scope.row.createdAt).format("MMM DD, YYYY")
                  : "N/A"
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="">
          <template #default="scope">
            <el-tooltip
              :content="'Remove Wishlist'"
              placement="left"
              effect="light"
            >
              <el-icon
                class="cursor-pointer delete-icon"
                @click="delWishlist(scope.row._id)"
              >
                <DeleteFilled />
              </el-icon>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </CardToolbar>
</template>

<style lang="scss">
.delete-icon svg {
  width: 1.5em;
  height: 1.5em;
}
</style>
