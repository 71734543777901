<script>
import { ref, watch } from "vue";
import ModalToolbar from "@/common/components/ModalToolbar.vue";
import { tableCellClassName, tableRowClassName } from "@/utils/staticHelper";
import { getSimilarPlaylists } from "@/api/playlists.api";
import { ElNotification } from "element-plus";
import { useRoute } from "vue-router";
import { saveRecommendedPlaylists } from "@/api/tracks.api";
export default {
  name: "SimilarPlaylistModal",
  methods: { tableCellClassName, tableRowClassName },
  components: { ModalToolbar },
  props: {
    curatorPlaylistID: {
      type: Number,
      default: null,
    },
    playlists: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const loading = ref(false);
    const closeModal = ref(null);
    const similarPlaylists = ref([]);
    const selectedPlaylists = ref([]);
    const allSelected = ref(false);
    const limit = ref(20);
    const route = useRoute();
    const fetchPlaylists = async () => {
      try {
        loading.value = true;
        const { data } = await getSimilarPlaylists(
          props.curatorPlaylistID,
          limit.value
        );
        let apiData = data && data.data && data.data.length ? data.data : [];
        if (apiData.length && props.playlists.length) {
          similarPlaylists.value = apiData.filter(
            (item2) =>
              !props.playlists.some(
                (item1) => item1.curatorPlaylistID === item2.id
              )
          );
        } else {
          similarPlaylists.value = apiData;
        }
        apiData = [];
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const showMore = () => {
      limit.value += 10;
      fetchPlaylists();
    };

    const checkBoxValueChanged = (event, id) => {
      allSelected.value = false;
      if (event && !selectedPlaylists.value.includes(id)) {
        selectedPlaylists.value.push(id);
      } else if (!event) {
        const index = selectedPlaylists.value.indexOf(id);
        if (index > -1) {
          selectedPlaylists.value.splice(index, 1);
        }
      }
    };

    const selectAllClicked = (value) => {
      if (value) {
        selectedPlaylists.value = similarPlaylists.value.map(
          (object) => object.id
        );
      } else {
        selectedPlaylists.value = [];
      }
    };

    const savePlaylists = () => {
      try {
        loading.value = true;

        let recommendedPlaylists = similarPlaylists.value
          .filter((g) => selectedPlaylists.value.includes(g.id))
          .map((object) => {
            return object;
          });

        let payload = {
          recommendedPlaylists,
          chartmetricTrackID: Number(route.params.chartmetricTrackID),
        };

        saveRecommendedPlaylists(payload);

        recommendedPlaylists = [];
        payload = {};
        allSelected.value = false;
        selectedPlaylists.value = [];
        ElNotification({
          title: "Success",
          message: "It will take some time in saving playlists",
          type: "success",
        });
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Something went wrong",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    watch(
      () => props.curatorPlaylistID,
      (value) => {
        if (value) {
          fetchPlaylists();
        }
      },
      { immediate: true }
    );
    return {
      loading,
      closeModal,
      similarPlaylists,
      limit,
      allSelected,
      selectedPlaylists,
      showMore,
      checkBoxValueChanged,
      selectAllClicked,
      savePlaylists,
    };
  },
};
</script>
<template>
  <ModalToolbar id="show_similar_playlist_modal" max-width="mw-800px">
    <template v-slot:header>
      <div class="modal-header" id="kt_modal_create_api_key_header">
        <h2>Similar Playlists</h2>
      </div>
    </template>
    <template v-slot:body>
      <div class="modal-body scroll-y py-0 mt-8" style="max-height: 500px">
        <div class="d-flex justify-content-end align-items-center mb-3">
          <el-checkbox
            class="me-5"
            :disabled="similarPlaylists.length === 0"
            v-model="allSelected"
            @change="selectAllClicked"
            label="Select all on page"
            size="large"
          ></el-checkbox>
          <button
            :disabled="
              selectedPlaylists.length === 0 ||
              similarPlaylists.length === 0 ||
              loading
            "
            class="
              btn btn-sm btn-color-muted btn-active btn-active-primary
              me-1
              active
            "
            @click="savePlaylists()"
          >
            Save
          </button>
        </div>
        <el-table
          v-loading="loading"
          :fit="true"
          size="small"
          :data="similarPlaylists"
          tooltip-effect="light"
          stripe
          style="width: 100%"
          header-cell-class-name="table-header-text"
          :row-class-name="tableRowClassName"
          :cell-class-name="tableCellClassName"
        >
          <el-table-column label="Select" width="80" fixed>
            <template #default="scope">
              <el-checkbox
                :model-value="selectedPlaylists.includes(scope.row.id)"
                :disabled="
                  !selectedPlaylists.includes(scope.row.id) &&
                  selectedPlaylists.length > 49
                "
                @change="checkBoxValueChanged($event, scope.row.id)"
                size="large"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            label="Name"
            class-name="cursor-pointer"
            fixed
            width="200"
          >
            <template #default="scope">
              <p
                class="truncate"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="scope.row.name ? scope.row.name : ''"
              >
                {{ scope.row.name ? scope.row.name : "N/A" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="Curator">
            <template #default="scope">
              <p
                class="truncate"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="scope.row.owner_name ? scope.row.owner_name : ''"
              >
                {{ scope.row.owner_name ? scope.row.owner_name : "N/A" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="Followers">
            <template #default="scope">
              <p
                class="truncate"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="scope.row.followers ? scope.row.followers : ''"
              >
                {{ scope.row.followers ? scope.row.followers : "N/A" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="Total Tracks">
            <template #default="scope">
              <p
                class="truncate"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="scope.row.num_track ? scope.row.num_track : ''"
              >
                {{ scope.row.num_track ? scope.row.num_track : "N/A" }}
              </p>
            </template>
          </el-table-column>
        </el-table>
        <div
          v-if="!loading"
          @click="showMore"
          class="
            cursor-pointer
            d-flex
            justify-content-center
            mt-4
            mb-4
            p-2
            fw-bolder
            theme-v2-color
          "
          style="background: #dee4ee; border-radius: 4px"
        >
          + Show 10 More Playlists
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal-footer flex-end bg-light-grey">
        <button
          ref="closeModal"
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
          @click="(similarPlaylists = []), (limit = 20)"
        >
          Close
        </button>
      </div>
    </template>
  </ModalToolbar>
</template>
